import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";

type Resident = {
  id: string;
  name: string;
  species: string;
  redList: string; // Changed to lowercase 'string'
  imageUrl?: string; // imageUrl is optional
};

const ResidentsList: React.FC = () => {
  const [residents, setResidents] = useState<Resident[]>([]);
  const [filteredResidents, setFilteredResidents] = useState<Resident[]>([]);
  const [redListOptions, setRedListOptions] = useState<string[]>([]);
  const [selectedRedList, setSelectedRedList] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "residents"));
        const residentsData = querySnapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as Resident)
        ); // Type assertion to Resident

        setResidents(residentsData);
        setFilteredResidents(residentsData);

        // Extract unique redList options
        const options = Array.from(
          new Set(residentsData.map((resident) => resident.redList))
        );
        setRedListOptions(options);
      } catch (error) {
        console.error("Error fetching residents:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedRedList === "") {
      setFilteredResidents(residents);
    } else {
      setFilteredResidents(
        residents.filter((resident) => resident.redList === selectedRedList)
      );
    }
  }, [selectedRedList, residents]);

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setSelectedRedList(event.target.value as string);
  };

  return (
    <Box padding={2}>
      <FormControl fullWidth variant='filled' margin='dense'>
        <InputLabel>Filter by Red List Status</InputLabel>
        <Select
          value={selectedRedList}
          onChange={handleFilterChange}
          label='Filter by Red List Status'>
          <MenuItem value=''>
            <em>All</em>
          </MenuItem>
          {redListOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>
            Park Residents
          </Typography>
        </Grid>
        {filteredResidents.map((resident) => (
          <Grid item xs={12} sm={6} md={4} key={resident.id}>
            <Paper
              elevation={3}
              style={{
                padding: "16px",
                display: "flex",
                alignItems: "center",
              }}>
              <Box flex={2} paddingRight={2}>
                <Typography variant='h5'>{resident.name}</Typography>
                <Typography variant='body1'>{resident.species}</Typography>
                <Typography variant='subtitle1'>{resident.redList}</Typography>
              </Box>
              {resident.imageUrl && (
                <Box flex={1}>
                  <img
                    src={resident.imageUrl}
                    alt={resident.name}
                    style={{ width: "100%", borderRadius: "4px" }}
                  />
                </Box>
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ResidentsList;
