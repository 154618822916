import "./App.css";
import React from "react";
import theme from "./theme";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga4";

import FirstLoad from "./pages/FirstLoad";
import MainPage from "./pages/MainPage";
import { PlanYourVisit } from "./pages/PlanYourVisit";
import { Contact } from "./pages/Contact";
import { GiftCard } from "./pages/GitftCard";
import { MainSimplePage } from "./pages/MainSimplePage";
import { Party } from "./pages/Party";
import { useTranslation } from "react-i18next";
import { TCPage } from "./pages/TCPage";
import { FAQ } from "./pages/FAQ";
import { School } from "./pages/School";
import { Map } from "./pages/Map";
import { Multipass } from "./pages/Multipass";
// import { Easter } from "./pages/Easter";
import { Workshop } from "./pages/Workshop";
import { Residents } from "./pages/Residents";

ReactGA.initialize("G-RNKZ267Z00");

function App() {
  const { t, i18n } = useTranslation();

  // Hide the loading spinner once the app is ready
  React.useEffect(() => {
    const loadingSpinner = document.getElementById("loading-spinner");
    if (loadingSpinner) {
      loadingSpinner.style.display = "none";
    }
  }, []);

  // Helper function to define routes based on the language code
  const getRoutes = (language: string) => {
    switch (language) {
      case "it":
      case "ru":
        return (
          <Routes>
            <Route path='/' element={<FirstLoad />} />
            <Route path='/:lang/simpleHome' element={<MainSimplePage />} />
            <Route
              path='*'
              element={<Navigate to={`/${language}/simpleHome`} replace />}
            />
          </Routes>
        );
      case "en-gb":
      case "es":
      case "ca":
        return (
          <Routes>
            <Route path='/' element={<FirstLoad />} />
            <Route path='/:lang/home' element={<MainPage />} />
            <Route path='/:lang/simpleHome' element={<MainSimplePage />} />
            <Route path='/:lang/planYourVisit' element={<PlanYourVisit />} />
            <Route path='/:lang/giftCard' element={<GiftCard />} />
            <Route path='/:lang/contact' element={<Contact />} />
            <Route path='/:lang/FAQ' element={<FAQ />} />
            <Route path='/:lang/party' element={<Party />} />
            <Route path='/:lang/school' element={<School />} />
            <Route path='/:lang/tc' element={<TCPage />} />
            <Route path='/:lang/map' element={<Map />} />
            <Route path='/:lang/multipass' element={<Multipass />} />
            {/* <Route path="/:lang/easter" element={<Easter />} /> */}
            <Route path='/:lang/workshop' element={<Workshop />} />
            <Route path='/:lang/residents' element={<Residents />} />
            {/* handles non-existent pages aka. 404 */}
            <Route
              path='*'
              element={<Navigate to={`/${language}/home`} replace />}
            />
          </Routes>
        );
      default:
        return (
          <Routes>
            <Route path='/' element={<FirstLoad />} />
            <Route path='*' element={<Navigate to='/es/home' replace />} />
          </Routes>
        );
    }
  };

  // Extract language code from URL
  const { lang } = useParams();
  const language = lang || i18n.language;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CookieConsent
        location='bottom'
        expires={7}
        acceptOnScroll={true}
        acceptOnScrollPercentage={50}
        buttonText={t("cookiesOkay")}
        cookieName='myCookieConsent'
        style={{ background: "#4caf50", color: "white" }}
        buttonStyle={{
          background: "white",
          color: "#4caf50",
          fontSize: "20px",
        }}>
        {t("cookies")}
      </CookieConsent>
      <Router>
        <div className='App'>{getRoutes(language)}</div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
