// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDP-c23Vq4jZYttzeWu-zSLftvIi86AQQk",
    authDomain: "el-jardi-dels-ocells.firebaseapp.com",
    projectId: "el-jardi-dels-ocells",
    storageBucket: "el-jardi-dels-ocells.appspot.com",
    messagingSenderId: "499919327522",
    appId: "1:499919327522:web:949e2efd92937e37e8d57b",
    measurementId: "G-70XCB4H7KN"
  };
  
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
